import React from "react";
import "./Button.styles.scss";

const Button = ({ label, href, onClick, ...otherProps }) => {
  return href ? (
    <a target="_blank" href={href} className="button" rel="noreferrer" {...otherProps}>
      {label}
    </a>
  ) : (
    <button onClick={onClick} className="button" {...otherProps}>
      {label}
    </button>
  );
};

export default Button;

import React from "react";
import "./About.styles.scss";
import { Container } from "../../modules/bootstrap";
import SectionHeading from "../SectionHeading/SectionHeading.component";
import CircularImage from "../CircularImage/CircularImage.component";
import { graphql, useStaticQuery } from "gatsby";

const SUMMARY_IMAGES = [
  {
    caption: "university of Toronto",
    imgName: "uToronto",
    alt: "University of Toronto"
  },
  {
    caption: "software developer",
    imgName: "CompSci",
    alt: "Coding"
  },
  {
    caption: "avid learner",
    imgName: "learning",
    alt: "Learning"
  }
];

const About = () => {
  const {
    allFile: { nodes: images }
  } = useStaticQuery(
    graphql`
      {
        allFile(filter: { relativeDirectory: { eq: "about" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 60, width: 200, height: 200)
            }
            name
          }
        }
      }
    `
  );

  return (
    <section id="about" className="about">
      <Container>
        <SectionHeading title="About" />
        <p className="about__description">
          I studied computer science with a focus in Software Engineering at the University of Toronto Scarborough. I
          have an {""}
          <span className="text-emphasis">insatiable desire to learn and work </span> with new technologies and tools.
        </p>
        <div className="about__summary">
          {SUMMARY_IMAGES.map((si) => {
            const imageSharp = images.find((i) => i.name === si.imgName) || null;
            return <CircularImage key={si.caption} {...si} extraClasses="col-11 col-md-4" imageSharp={imageSharp} />;
          })}
        </div>
      </Container>
    </section>
  );
};

export default About;

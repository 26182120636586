import React, { useState } from "react";
import "./Projects.styles.scss";
import SectionHeading from "../SectionHeading/SectionHeading.component";
import { Container } from "../../modules/bootstrap";
import Filters from "./Filters/Filters.component";
import Project from "./Project/Project.component";
import { graphql, useStaticQuery } from "gatsby";
import DetailsModal from "./DetailsModal/DetailsModal.component";

const PROJECTS = [
  {
    title: "FanCrowd",
    category: "web",
    previewImgName: "fan_crowd",
    techStack: ["Node.js", "Express.js", "Angular", "Docker", "Angular Material", "SASS", "MongoDB"],
    description:
      "A web app where users can attend events created by others users, give reviews for those events, and come together to discuss topics such as movies, technology, books and much more.\n\n Demo Credentials:\n Username: user2\n Password: user2\n",
    sourceLink: "https://github.com/Chandra-Panta-Chhetri/fancrowd",
    demoLink: "https://fancrowd.cyclic.app"
  },
  {
    title: "Crown Computers",
    category: "web",
    previewImgName: "crown_computers",
    techStack: [
      "Node.js",
      "Express.js",
      "React",
      "Redux",
      "Redux Saga",
      "Styled Components",
      "Firebase",
      "Stripe",
      "SASS"
    ],
    description:
      "A responsive e-commerce PWA (progressive web app) that sells all things to do with computers. Customers can browse products, add them to their cart, save items to wish list(s), and check out their cart. Whereas, admins can view sale history, add new products, update existing products, create new product categories, and more.\n\n Demo Credentials:\n Email: demo.user@gmail.com\n Password: thisisfordemo",
    sourceLink: "https://github.com/Chandra-Panta-Chhetri/crown-computers",
    demoLink: "https://crown-computers.cyclic.app"
  },
  {
    title: "TECHnically A Review",
    category: "web",
    previewImgName: "technically_a_review",
    techStack: ["Node.js", "Express.js", "Passport.js", "Cloudinary", "Nodemailer", "Mongo DB", "Bootstrap"],
    description:
      "A RESTful web app where users can create tech product posts and review tech products by commenting and rating. Users will also receive real-time notifications when other users review their posts.\n\n Demo Credentials:\n Email: demo@gmail.com\n Password: password",
    sourceLink: "https://github.com/Chandra-Panta-Chhetri/technically-a-review",
    demoLink: "https://technically-a-review.onrender.com"
  },
  {
    title: "Connect 4",
    category: "other",
    previewImgName: "connect-4",
    techStack: ["Python", "Pygame"],
    description:
      "Recreated classic two-player game using Pygame GUI where users can play using the trackpad on any board size (default is 6x7).",
    sourceLink: "https://github.com/Chandra-Panta-Chhetri/Connect_4_Python"
  }
];

const FILTERS = ["all", ...new Set(PROJECTS.map((p) => p.category))];

const Projects = () => {
  const {
    allFile: { nodes: images }
  } = useStaticQuery(
    graphql`
      {
        allFile(filter: { relativeDirectory: { eq: "projects" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 60, width: 400, height: 400)
            }
            name
          }
        }
      }
    `
  );

  const [activeFilter, setActiveFilter] = useState(FILTERS.length > 0 ? FILTERS[0] : null);
  const [shownProjectIndex, setShownProjectIndex] = useState(null);

  const changeFilter = (newFilter) => activeFilter !== newFilter && setActiveFilter(newFilter);
  const openModal = (newIndex) => {
    newIndex !== shownProjectIndex && setShownProjectIndex(newIndex);
    document.body.style.overflowY = "hidden";
  };
  const closeModal = () => {
    setShownProjectIndex(null);
    document.body.style.overflowY = "auto";
  };

  return (
    <section id="projects" className="projects">
      <Container>
        <SectionHeading title="projects" />
        <Filters filters={FILTERS} onChange={changeFilter} activeFilter={activeFilter} />
        <div className="projects__overview">
          {PROJECTS.map((p, i) => {
            const img = images.find((i) => i.name === p.previewImgName) || null;
            const isShown = activeFilter === "all" || activeFilter === p.category;
            return (
              <Project
                previewImgSharp={img}
                key={p.title}
                onClick={() => openModal(i)}
                extraClasses={`col-11 col-md-5 col-xl-3 ${isShown ? "" : "project--hidden"}`}
              />
            );
          })}
        </div>
      </Container>
      <DetailsModal closeModal={closeModal} isVisible={shownProjectIndex !== null} {...PROJECTS[shownProjectIndex]} />
    </section>
  );
};

export default Projects;

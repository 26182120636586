import React, { useState } from "react";
import "./Contact.styles.scss";
import { Container, Form } from "../../modules/bootstrap";
import SectionHeading from "../SectionHeading/SectionHeading.component";
import Button from "../Button/Button.component";
import { toast } from "react-toastify";
import { sendFormData } from "../../modules/api";

const Contact = () => {
  const [wasValidated, setWasValidated] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    message: ""
  });
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() && !isSending) {
      setIsSending(true);
      const successfullySent = await sendFormData(formData, form.getAttribute("name"));
      if (successfullySent) {
        form.reset();
        setWasValidated(false);
        toast.success("Message sent!");
      } else {
        toast.error("There was an error. Please try again.");
      }
      setIsSending(false);
    } else {
      setWasValidated(true);
    }
  };

  return (
    <section id="contact" className="contact">
      <Container>
        <SectionHeading title="Contact" />
        <div className="contact__form">
          <Form noValidate validated={wasValidated} name="contact" onSubmit={handleSubmit} data-netlify="true">
            <Form.Group className="mb-3" controlId="email">
              <Form.Label className="contact__form-label">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="john@gmail.com"
                required
                name="email"
                onChange={handleChange}
                pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,3})$"
              />
              <Form.Control.Feedback type="invalid">Please enter a valid email.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="message">
              <Form.Label className="contact__form-label">Message</Form.Label>
              <Form.Control
                name="message"
                as="textarea"
                rows={3}
                required
                placeholder="Hi..."
                minLength={1}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">Please enter a message.</Form.Control.Feedback>
            </Form.Group>
            <Button type="submit" label={isSending ? "Sending..." : "Send"} disabled={isSending} />
          </Form>
        </div>
      </Container>
    </section>
  );
};

export default Contact;

import React, { useEffect, useRef } from "react";
import "./PageNotFound.styles.scss";
import { Link } from "gatsby";
import lottie from "lottie-web";
import { Container } from "../../modules/bootstrap";
import animation from "../../../static/animations/page-not-found.json";

const PageNotFound = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation
    });
    return () => anim.destroy();
  }, []);

  return (
    <section className="page-not-found">
      <Container>
        <div className="page-not-found__animation" ref={animationContainer}></div>
        <h4 className="page-not-found__title">The page you're looking for wasn't found!</h4>
        <Link to="/" replace className="button">
          Back to Home
        </Link>
      </Container>
    </section>
  );
};

export default PageNotFound;

import React from "react";
import "./Articles.styles.scss";
import { Container } from "../../modules/bootstrap";
import SectionHeading from "../SectionHeading/SectionHeading.component";
import Article from "./Article/Article.component";

const ARTICLE_POSTS = [
  {
    title: "Docker, Postgres, Node, Typescript Setup",
    date: "Jan 9, 2021",
    link: "https://dev.to/chandrapantachhetri/docker-postgres-node-typescript-setup-47db"
  },
  {
    title: "Infinite Scroll With Firebase, React, Intersection Observer & Redux Saga",
    date: "Jan 11, 2020",
    link: "https://dev.to/chandrapantachhetri/infinite-scroll-pagination-with-firebase-react-intersection-observer-redux-saga-4fd"
  },
  {
    title: "Responsive React File Upload Component With Drag And Drop",
    date: "Jan 2, 2020",
    link: "https://dev.to/chandrapantachhetri/responsive-react-file-upload-component-with-drag-and-drop-4ef8"
  },
  {
    title: "Sending Emails With Nodemailer, SMTP, Gmail, & OAuth2",
    date: "Dec 16, 2020",
    link: "https://dev.to/chandrapantachhetri/sending-emails-securely-using-node-js-nodemailer-smtp-gmail-and-oauth2-g3a"
  },
  {
    title: "Firebase Security Rule",
    date: "Dec 5, 2020",
    link: "https://dev.to/chandrapantachhetri/firebase-security-rules-43kn"
  }
];

const Articles = () => {
  return (
    <section id="articles" className="articles">
      <Container>
        <SectionHeading title="Articles" />
        <div className="articles__overview">
          {ARTICLE_POSTS.map((ap) => (
            <Article key={ap.title} {...ap} extraClasses="col-11 col-md-4 col-lg-3" />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Articles;

import React from "react";
import "./SocialIcons.styles.scss";
import { GithubIcon, LinkedinIcon } from "../../modules/icons";

const SOCIALS = [
  {
    href: "https://github.com/Chandra-Panta-Chhetri",
    title: "github",
    Icon: GithubIcon,
    color: "#000"
  },
  {
    href: "https://www.linkedin.com/in/chandra-panta-chhetri/",
    title: "linkedin",
    Icon: LinkedinIcon,
    color: "#0077b5"
  }
];

const SocialIcon = ({ href, Icon, color }) => (
  <a target="_blank" href={href} rel="noreferrer">
    {[1, 2].map((i) => (
      <span key={i} style={{ backgroundColor: color }}></span>
    ))}
    <span style={{ backgroundColor: color }}>
      <Icon />
    </span>
  </a>
);

const SocialIcons = () => {
  return (
    <aside className="social-icons">
      <ul className="social-icons__list">
        {SOCIALS.map(({ title, ...other }) => (
          <li key={title} className="social-icons__list-item">
            <SocialIcon {...other} />
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default SocialIcons;

import React from "react";
import "./CircularImage.styles.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CircularImage = ({ imageSharp, alt = "", loading = "lazy", caption, extraClasses = "" }) => {
  const image = getImage(imageSharp);

  return (
    <div className={`circular-image ${extraClasses}`}>
      <div className="circular-image__img-container">
        {image && <GatsbyImage className="circular-image__img" alt={alt} image={image} loading={loading} />}
      </div>
      {caption && <h4 className="circular-image__caption">{caption}</h4>}
    </div>
  );
};

export default CircularImage;

import React from "react";
import "./SectionHeading.styles.scss";

const SectionHeading = ({ title, subtitle }) => (
  <header className="section-heading">
    <h2 className="section-heading__title">{title}</h2>
    {subtitle && <h3 className="section-heading__subtitle">{subtitle}</h3>}
  </header>
);

export default SectionHeading;

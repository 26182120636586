import React from "react";
import "./Category.styles.scss";

const Category = ({ name, icons = [], extraClasses = "" }) => {
  return (
    <section className={`skills-category ${extraClasses}`}>
      <h3 className="skills-category__title">{name}</h3>
      <div className="skills-category__icons">
        {icons.map(({ color, Icon, label }, i) => (
          <Icon title={label} className="skills-category__icon" key={i} color={color} />
        ))}
      </div>
    </section>
  );
};

export default Category;

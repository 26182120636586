const encodeFormData = (data) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

export const sendFormData = async (data, formName, url = "/") => {
  try {
    await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeFormData({
        "form-name": formName,
        ...data
      })
    });
    return true;
  } catch (err) {
    return false;
  }
};

import React from "react";
import "./Filters.styles.scss";
import Chip from "../../Chip/Chip.component";

const ProjectFilters = ({ filters = [], onChange, activeFilter }) => (
  <div className="project-filters">
    {filters.map((f) => (
      <Chip onClick={() => onChange && onChange(f)} isActive={activeFilter === f} label={f} key={f} />
    ))}
  </div>
);

export default ProjectFilters;

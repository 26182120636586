import React from "react";
import ReactDOM from "react-dom/client";
import { Layout } from "./src/components";
import "react-toastify/dist/ReactToastify.min.css";
import "./src/sass/main.scss";

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export const wrapRootElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;

import React from "react";
import "./Timeline.styles.scss";

const Timeline = ({ events = [] }) => {
  return (
    <ul className="timeline">
      {events.map(({ title, subtitle, Image, keyPoints = [] }, i) => {
        const isInverted = (i + 1) % 2 === 0;
        return (
          <li key={i} className={`timeline__event ${isInverted ? "timeline__event--inverted" : ""}`}>
            <div className="timeline__image">{Image}</div>
            <article className="timeline__details">
              <header>
                <h4 className="timeline__title text-emphasis">{title}</h4>
                <h4 className="timeline__subtitle">{subtitle}</h4>
              </header>
              <div>
                <ul className="timeline__key-points">
                  {keyPoints.map((p) => (
                    <li key={p}>{p}</li>
                  ))}
                </ul>
              </div>
            </article>
          </li>
        );
      })}
    </ul>
  );
};

export default Timeline;

import React from "react";
import Footer from "./Footer/Footer.component";
import { ToastContainer } from "react-toastify";

const TOAST_DURATION = 3000;

const Layout = ({ children }) => (
  <>
    {children}
    <ToastContainer
      position="top-left"
      autoClose={TOAST_DURATION}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
    />
    <Footer />
  </>
);

export default Layout;

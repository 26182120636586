import React from "react";
import "./Article.styles.scss";

const Article = ({ title, date, link, extraClasses = "" }) => {
  return (
    <article className={`article ${extraClasses}`}>
      <time className="article__date">{date}</time>
      <a className="article__title" href={link} target="_blank" rel="noreferrer">
        {title}
      </a>
    </article>
  );
};

export default Article;

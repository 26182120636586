import React from "react";
import "./Skills.styles.scss";
import { Container } from "../../modules/bootstrap";
import SectionHeading from "../SectionHeading/SectionHeading.component";
import Category from "./Category/Category.component";
import {
  ReactIcon,
  SassIcon,
  AngularIcon,
  NodeJsIcon,
  MongodbIcon,
  DockerIcon,
  JavaScriptIcon,
  GatsbyIcon,
  PostgresqlIcon,
  HTMLIcon,
  CSSIcon,
  NextJSIcon,
  GitIcon,
  JiraIcon,
  TrelloIcon
} from "../../modules/icons";

const CATEGORIES = [
  {
    name: "front end",
    icons: [
      { color: "#c69", Icon: SassIcon, label: "SASS" },
      { color: "#7026b9", Icon: GatsbyIcon, label: "Gatsby" },
      { color: "#61dafb", Icon: ReactIcon, label: "React" },
      { color: "#c4473a", Icon: AngularIcon, label: "Angular" },
      { color: "#E24C25", Icon: HTMLIcon, label: "HTML" },
      { color: "#28A9E2", Icon: CSSIcon, label: "CSS" }
    ]
  },
  {
    name: "back end",
    icons: [
      { color: "#83cd29", Icon: NodeJsIcon, label: "Node.js" },
      { color: "#4faa41", Icon: MongodbIcon, label: "MongoDB" },
      { color: "#1384c8", Icon: PostgresqlIcon, label: "Postgresql" },
      { color: "#000000", Icon: NextJSIcon, label: "Next.js" }
    ]
  },
  {
    name: "project management",
    icons: [
      { color: "#EF4F32", Icon: GitIcon, label: "Git" },
      { color: "#1064DF", Icon: JiraIcon, label: "Jira" },
      { color: "#1A7DF7", Icon: TrelloIcon, label: "Trello" }
    ]
  },
  {
    name: "programming languages",
    icons: [{ color: "#EFDA4E", Icon: JavaScriptIcon, label: "JavaScript" }]
  },
  {
    name: "devops",
    icons: [{ color: "#019bc6", Icon: DockerIcon, label: "Docker" }]
  }
];

const Skills = () => {
  return (
    <section id="skills" className="skills">
      <Container>
        <SectionHeading title="Skills" />
        <div className="skills__overview">
          {CATEGORIES.map(({ name, icons }) => (
            <Category name={name} icons={icons} key={name} extraClasses="col-11 col-sm-6 col-lg-4 mb-4" />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Skills;

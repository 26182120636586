import React from "react";
import "./Footer.styles.scss";
import { Container } from "../../modules/bootstrap";

const currentYear = new Date().getFullYear();

const Footer = () => (
  <footer className="pt-4 pb-2 footer">
    <Container>
      <h4 className="footer__label">&#169; {currentYear} Chandra Panta Chhetri. All Rights Reserved.</h4>
    </Container>
  </footer>
);

export default Footer;

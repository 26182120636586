import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

const siteMetadataQuery = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
        description
        image
      }
    }
  }
`;

const Seo = ({ title }) => {
  const { site } = useStaticQuery(siteMetadataQuery);
  const seo = site.siteMetadata;

  return (
    <Helmet htmlAttributes={{ lang: "en" }} title={seo.title} titleTemplate={title && `%s | ${title}`}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.siteUrl && <meta property="og:url" content={seo.siteUrl} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

export default Seo;

import React from "react";
import "./Hero.styles.scss";
import SocialIcons from "../SocialIcons/SocialIcons.component";
import Button from "../Button/Button.component";
import { StaticImage } from "gatsby-plugin-image";

const RESUME_LINK = "https://drive.google.com/open?id=1B9Hp-VmxFZMV3OmjGSNdIA_grjzdFT6j";

const Hero = () => (
  <header className="hero" id="hero">
    <article className="hero__content">
      <h1 className="hero__heading text-capitalize">Software Developer</h1>
      <SocialIcons />
      <Button label="resume" href={RESUME_LINK} />
    </article>
    <StaticImage
      alt="hero background"
      src="../../images/header-bg.jpg"
      placeholder="blurred"
      className="hero__background"
      loading="eager"
    />
  </header>
);

export default Hero;

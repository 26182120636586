import React from "react";
import "./Chip.styles.scss";

const Chip = ({ label, isActive = false, onClick }) => (
  <button onClick={onClick} className={`chip ${isActive ? "chip--active" : ""}`}>
    {label}
  </button>
);

export default Chip;

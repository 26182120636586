import React from "react";
import "./DetailsModal.styles.scss";
import { CloseIcon } from "../../../modules/icons";
import Badge from "../../Badge/Badge.component";
import Button from "../../Button/Button.component";

const DetailsModal = ({ title, techStack = [], description, demoLink, sourceLink, isVisible, closeModal }) => {
  return (
    <div className={`details-modal__overlay ${isVisible ? "details-modal__overlay--show" : ""}`}>
      <article className={`details-modal ${isVisible ? "details-modal--show" : ""}`}>
        <header className="details-modal__header">
          <h2 className="details-modal__title">{title}</h2>
          <CloseIcon className="details-modal__close" onClick={closeModal} />
        </header>
        <section className="details-modal__tech-stack">
          {techStack.map((ts) => (
            <Badge label={ts} key={ts} />
          ))}
        </section>
        <p className="details-modal__description">{description}</p>
        <section className="details-modal__links">
          {demoLink && <Button label={"Demo"} href={demoLink} />}
          {sourceLink && <Button label={"Source"} href={sourceLink} />}
        </section>
      </article>
    </div>
  );
};

export default DetailsModal;

import React from "react";
import "./Project.styles.scss";
import Button from "../../Button/Button.component";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const Project = ({ previewImgSharp, extraClasses = "", onClick }) => {
  const image = getImage(previewImgSharp);

  return (
    <article className={`project ${extraClasses}`} onAnimationEnd={(event) => console.log(event)}>
      {image && <GatsbyImage className="project__img" alt="Project Image" image={image} loading="lazy" />}
      <div className="project__content">
        <Button onClick={onClick} label="Details" />
      </div>
    </article>
  );
};

export default Project;

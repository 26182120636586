import React from "react";
import "./Experiences.styles.scss";
import { Container } from "../../modules/bootstrap";
import SectionHeading from "../SectionHeading/SectionHeading.component";
import Timeline from "../Timeline/Timeline.component";
import { StaticImage } from "gatsby-plugin-image";

const WORK_EXPERIENCES = [
  {
    title: "Software Developer",
    subtitle: "Amdocs - Bell Project (Sep 2022 - Present)",
    keyPoints: [
      "Built Node.js script that generated C# models from Swagger YAML file within 5 seconds which greatly reduced the time needed to update C# models",
      "Processed data from over 10 microservices to implement a timeline that showed a history of the customer’s activities, improving user experience for over 10 million customers",
      "Utilized excellent analytical skills & understanding of React when fixing over 100 production, performance, and accessibility defects",
      "Wrote design document used by more than 20 people that provided detailed backend & frontend changes for implementing the bill explainer feature",
      <span className="text-emphasis">Technologies: React, Redux, C#, Git</span>
    ],
    Image: <StaticImage src="../../images/experiences/amdocs.png" alt="Amdocs" placeholder="blurred" />
  },
  {
    title: "Software Engineer Intern",
    subtitle: "RBC (Sep 2021 - Dec 2021)",
    keyPoints: [
      "Employed excellent design skills when architecting front-end & back-end for web app used by over 20 teams, allowing web app to handle over 10,000 entries of data",
      "Significantly reduced deployment time to 1-2 minutes by simplifying the deployment process",
      <span className="text-emphasis">Technologies: Python, Flask, MSSQL, Angular, Angular Material, Git</span>
    ],
    Image: <StaticImage src="../../images/experiences/rbc.png" alt="RBC" placeholder="blurred" />
  },
  {
    title: "Software Engineer Intern",
    subtitle: "DBRS Morningstar (Sep 2020 - Dec 2020)",
    keyPoints: [
      "Restructured more than 10 MSSQL database tables, allowing for data scalability & easier data access",
      "Improved user experience, API, & database reliability by using excellent analytical skills to fix over 50 bugs",
      <span className="text-emphasis">
        Technologies: .NET Core, MSSQL, Entity Framework Core, Angular, Angular Material, C#, Git, TeamCity
      </span>
    ],
    Image: <StaticImage src="../../images/experiences/dbrs.png" alt="DBRS" placeholder="blurred" />
  },
  {
    title: "Freelance Web Developer",
    subtitle: (
      <>
        <a href={"https://www.zeeraindiancuisine.ca"} className="link" target="_blank" rel="noreferrer">
          Zeera by the Bay
        </a>{" "}
        <span>(Mar 2020 - Apr 2020)</span>
      </>
    ),
    keyPoints: [
      "Migrated from a website built using GoDaddy Website Builder to one built using HTML, CSS, PHP, JavaScript, jQuery, Bootstrap & hosted on HostPapa, reducing yearly business costs by $500",
      "Increased the number of daily customers & reservation bookings by 10% after improving website interactivity, design, and ease of use when booking reservations",
      <span className="text-emphasis">Technologies: JavaScript, jQuery, PHP, HTML, CSS, Bootstrap, Git</span>
    ],
    Image: <StaticImage src="../../images/experiences/freelance.png" alt="Freelance" placeholder="blurred" />
  },
  {
    title: "QA Analyst Intern",
    subtitle: "Clearbridge Mobile (Jan 2020 - Apr 2020)",
    keyPoints: [
      "Identified over 100 bugs through great attention to detail towards acceptance criteria of user stories, resulting in client satisfaction & improving user experience",
      "Improved work efficiency by taking initiative to learn Appium to automate testing & focus on more demanding tasks"
    ],
    Image: <StaticImage src="../../images/experiences/clearbridge.png" alt="Clearbridge Mobile" placeholder="blurred" />
  }
];

const Experiences = () => {
  return (
    <section id="experiences" className="experiences">
      <Container>
        <SectionHeading title="Experiences" />
        <div className="experiences__overview">
          <Timeline events={WORK_EXPERIENCES} />
        </div>
      </Container>
    </section>
  );
};

export default Experiences;
